import React from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Layout, TopNavigation } from '../components';
import {
  VideoScroller, FeaturedVideoScroller,
  FullScreenTextLoader,
  SiteLinks
} from '../components';
import serviceHelper from '../helpers/serviceHelper';
import categoryImage from '../images/categoryImage.jpg';
import ReactJwPlayer from '../helpers/jwplayer-react';
import env from '../config';
import '../styles/index.css'

class Homepage extends React.Component {
  state = {
    content: [],
    isLoading: false,
    featuredContent: null,
    playlistContent: [],
    initialLoad: true,
    imageLoadedCount: 0,
    isImagesLoaded: false,
    scrolled: false,
    liveFeedError: true,
  };

  componentDidMount() {
    this.getConfig();
    window.addEventListener("scroll", this.handleScroll);
    this.timer = setTimeout(() => {
      this.setState({ isImagesLoaded: true });
    }, 25000);
    window.addEventListener("resize", this.resizeLiveFeed);
  }

  componentDidUpdate() {
    this.resizeLiveFeed();
  }

  resizeLiveFeed = () => {
    const playlistSwimLanes = document.getElementById('playlist-swim-lanes');
    const liveFeedSwims = document.getElementById('live-feed-swims');
    const liveFeedParent = document.getElementById('live-feed-parent');
    const liveFeedTitle = playlistSwimLanes ? playlistSwimLanes.getElementsByTagName('h6')[0] : null;
    const liveFeed = document.getElementById('live-feed');
    if (!playlistSwimLanes || !liveFeedSwims || !liveFeedParent || !liveFeedTitle || !liveFeed) { return }

    const liveFeedSwimHeight = playlistSwimLanes.children[1].offsetHeight * 2;
    const liveFeedTitleHeight = liveFeedTitle.offsetHeight;

    let liveFeedHeight = liveFeedSwimHeight - liveFeedTitleHeight;
    let liveFeedWidth = liveFeedHeight * 16 / 9;
    let liveFeedSwimsWidth = '300px';

    if (liveFeedWidth > .6 * window.innerWidth) {
      liveFeedParent.style.flexDirection = 'column';
      liveFeedWidth = liveFeedParent.offsetWidth;
      liveFeedHeight = liveFeedWidth * 9 / 16 + liveFeedTitleHeight;
      liveFeedSwimsWidth = null;
    } else {
      liveFeedParent.style.flexDirection = 'row';
    }

    liveFeed.style.height = liveFeedHeight + 'px';
    liveFeed.style.width = liveFeedWidth + 'px';
    liveFeedSwims.style.width = liveFeedSwimsWidth;
  }

  seo = {
    title: "DOCUMENTARY+ | Stream Documentaries Free",
    description: `Home to the world’s best documentaries -- from Academy 
    Award winning classics and box office hits to festival darlings and cult favorites. 
    Nonfiction storytelling at its best, curated by the best.`,
  };

  getConfig = async () => {
    const config = await serviceHelper.getConfig();
    if (config.isError) {
      return;
    }
    const content = config.data && config.data.content;
    if (content && content.length) {
      const featured = content.find((i) => i.featured);
      let featuredData = [];
      if (featured) {
        featuredData = await serviceHelper.getPlaylist(featured.contentId);
        //featuredData.data.playlist.unshift({ smallFeaturedImage: categoryImage, type: 'celebrate-black-stories', feedid: '482tfLLK' })
      }
      // setting description in localstorage
      if (config.data && config.data.description) {
        localStorage.setItem("config", config.data.description);
      }
      this.setState(
        {
          content: config.data.content,
          featuredContent: featuredData.data,
          initialLoad: false,
        },
        () => {
          this.getPlaylists();
        }
      );
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    clearTimeout(this.timer);
  }

  setImagesLoaded = () => {
    if (!this.state.isImagesLoaded) {
      const imageLoadedCount = this.state.imageLoadedCount + 1;
      let isImagesLoaded = false;
      if (imageLoadedCount >= 4) {
        isImagesLoaded = true;
      }
      this.setState({ imageLoadedCount, isImagesLoaded });
    }
  };

  getPlaylists = async () => {
    const { isLoading, content } = this.state;
    if (isLoading || !(content && content.length)) return;
    this.setState({ isLoading: true }, async () => {
      const tempData = [...content];
      let pData = await Promise.all(
        [0, 1, 2, 3, 4, 5].map(async (item, index) => {
          const currData = tempData.shift(index);
          if (!currData) return null;
          if (currData.featured) {
            return null;
          }
          const playlistData = await serviceHelper.getPlaylist(
            currData.contentId
          );
          if (!playlistData.isError) {
            return playlistData.data;
          }
        })
      );
      pData = pData.filter((i) => i);
      this.setState({
        playlistContent: [...this.state.playlistContent, ...pData],
        content: tempData,
        isLoading: false,
      });
    });
  };

  isBottom = (el) => {
    if (!el) return false;
    const elemBottom = el.getBoundingClientRect().bottom;
    const height = window.innerHeight;
    return elemBottom - 200 <= height;
  };

  handleScroll = () => {
    const { content } = this.state;
    const wrappedElement = document.getElementById("header");
    if (!this.state.scrolled) {
      this.setState({ scrolled: true });
    }
    if (this.isBottom(wrappedElement)) {
      if (!content.length) {
        window.removeEventListener("scroll", this.handleScroll);
      } else {
        this.getPlaylists();
      }
    }
  };

  handleLiveVideoError = (e) => {
    this.setState({ liveFeedError: true });
  }

  handleLiveVideoSuccess = (e) => {
    this.setState({ liveFeedError: false });
  }

  renderItems = (featuredContent, playlistContent) => {
    if (!(featuredContent && playlistContent && playlistContent.length)) {
      return <div style={{ height: "90vh" }}></div>;
    }
    const { classes } = this.props;

    const liveFeedRows = <Box id='live-feed-parent'>
      <div style={{ visibility: 'hidden', height: '0px' }}>
        <ReactJwPlayer
          licenseKey={env.jwPlayerLicenseKey}
          library={env.jwPlayerScriptUrl}
          file='https://content.jwplatform.com/manifests/xNvS1y1k.m3u8'
          autostart={true}
          mute={true}
          onError={this.handleLiveVideoError}
          onSetupError={this.handleLiveVideoError}
          onReady={this.handleLiveVideoSuccess}
        />
      </div>
      {this.state.liveFeedError ? <></> :
        <Box id='live-feed'>
          <Typography className={classes.title} variant="h6">DOC+ live</Typography>
          <ReactJwPlayer
            licenseKey={env.jwPlayerLicenseKey}
            library={env.jwPlayerScriptUrl}
            file='https://documentaryplus-documentaryplus-3-us.ono.wurl.tv/playlist.m3u8'
            autostart={true}
            mute={true}
            onError={this.handleLiveVideoError}
            onSetupError={this.handleLiveVideoError}
          />
        </Box>}
      <Box id='live-feed-swims'>
        <VideoScroller
          landingPage={true}
          scrolled={this.state.scrolled}
          isImagesLoaded={this.state.isImagesLoaded}
          setImagesLoaded={this.setImagesLoaded}
          key={`Video${0}`}
          content={playlistContent[0]}
        />
        <VideoScroller
          landingPage={true}
          scrolled={this.state.scrolled}
          isImagesLoaded={this.state.isImagesLoaded}
          setImagesLoaded={this.setImagesLoaded}
          key={`Video${1}`}
          content={playlistContent[1]}
        />
      </Box>
    </Box>

    return (
      <React.Fragment>
        <section id="header" className={classes.featuredSection}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <FeaturedVideoScroller featuredContent={featuredContent} />
            </Grid>
          </Grid>
        </section>
        <section id="header" className={classes.playlistSection}>
          <Grid container justify="center">
            <Grid id='playlist-swim-lanes' item xs={12}>
              {liveFeedRows}
              {playlistContent.slice(2).map((item, index) => (
                <VideoScroller
                  landingPage={true}
                  scrolled={this.state.scrolled}
                  isImagesLoaded={this.state.isImagesLoaded}
                  setImagesLoaded={this.setImagesLoaded}
                  key={`Video${index}`}
                  content={item}
                />
              ))}
            </Grid>
          </Grid>
        </section>
      </React.Fragment>
    );
  };

  render() {
    const { location } = this.props;
    const { featuredContent, playlistContent, initialLoad, isImagesLoaded } =
      this.state;
    return (
      <Layout location={location} seo={this.seo}>
        <TopNavigation location={location} />
        <FullScreenTextLoader
          open={
            !(
              featuredContent &&
              playlistContent &&
              playlistContent.length &&
              isImagesLoaded
            )
          }
          timeout={1}
        />
        {this.renderItems(featuredContent, playlistContent)}
        <SiteLinks initialLoad={initialLoad} />
      </Layout>
    );
  }
}


const styles = (theme) => ({
  featuredSection: {
    margin: '15px',
    [theme.breakpoints.down(767)]: {
      margin: 0
    },
  },
  playlistSection: {
    margin: '15px',
    [theme.breakpoints.down(767)]: {
      margin: '0 15px',
      marginTop: '30px'
    },
  },
  title: {
    padding: '8px',
    marginLeft: '8px',
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '.85rem',
    },
  },
});

export default withStyles(styles)(Homepage);